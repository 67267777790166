define("apollo/pods/components/transport/create-delivery-from-transport/create-delivery-from-transport-form/component", ["exports", "apollo/mixins/step-mixin"], function (_exports, _stepMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_stepMixin.default, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    dateUtils: Ember.inject.service('date-utils'),

    filteredSteps(property) {
      const steps = this.get('steps').filterBy(`${property}.id`);
      return steps.filterBy('stepTypeName', this.get('step.stepTypeName') === 'load' ? 'load' : 'unload');
    },

    warehouseSteps: Ember.computed('steps', 'step.stepTypeName', function () {
      return this.filteredSteps('warehouse');
    }),
    recipientSteps: Ember.computed('steps', 'step.stepTypeName', function () {
      return this.filteredSteps('address');
    }),
    loadStepFormattedArrivalDate: Ember.computed('delivery.loadStep.arrivalDate', function () {
      return this.get('delivery.loadStep.arrivalDate') ? this.get('dateUtils').formatDate(this.get('delivery.loadStep.arrivalDate'), this.get('dateUtils').getDefaultDateFormat()) : '';
    }),
    loadStepFormattedMinAdviceDate: Ember.computed('delivery.loadStep.minAdviceDate', function () {
      return this.get('delivery.loadStep.minAdviceDate') ? this.get('dateUtils').formatDate(this.get('delivery.loadStep.minAdviceDate'), this.get('dateUtils').getDefaultDateFormat()) : '';
    }),
    loadStepFormattedMaxAdviceDate: Ember.computed('delivery.loadStep.maxAdviceDate', function () {
      return this.get('delivery.loadStep.maxAdviceDate') ? this.get('dateUtils').formatDate(this.get('delivery.loadStep.maxAdviceDate'), this.get('dateUtils').getDefaultDateFormat()) : '';
    }),
    unloadStepFormattedArrivalDate: Ember.computed('delivery.unloadStep', function () {
      return this.get('delivery.unloadStep.arrivalDate') ? this.get('dateUtils').formatDate(this.get('delivery.unloadStep.arrivalDate'), this.get('dateUtils').getDefaultDateFormat()) : '';
    }),
    unloadStepFormattedMinAdviceDate: Ember.computed('delivery.unloadStep.minAdviceDate', function () {
      return this.get('delivery.unloadStep.minAdviceDate') ? this.get('dateUtils').formatDate(this.get('delivery.unloadStep.minAdviceDate'), this.get('dateUtils').getDefaultDateFormat()) : '';
    }),
    unloadStepFormattedMaxAdviceDate: Ember.computed('delivery.unloadStep.maxAdviceDate', function () {
      return this.get('delivery.unloadStep.maxAdviceDate') ? this.get('dateUtils').formatDate(this.get('delivery.unloadStep.maxAdviceDate'), this.get('dateUtils').getDefaultDateFormat()) : '';
    }),
    selectedWarehouseStep: Ember.computed('step.warehouse', 'warehouseSteps.[]', function () {
      const warehouseStep = this.get('step.warehouse');
      return this.get('warehouseSteps').findBy('warehouse', warehouseStep);
    }),
    selectedRecipientStep: Ember.computed('step', 'recipientSteps.[]', function () {
      const recipientStep = this.get('step.address');
      return this.get('recipientSteps').findBy('address', recipientStep);
    }),

    setStep(deliveryStep, step, type) {
      const isLoadStep = deliveryStep.get('stepTypeName') === 'load';
      this.get('delivery').set(isLoadStep ? 'loadStep' : 'unloadStep', step);
      this.get('step.errors').remove(type);
    },

    showWarehouseCheckbox: Ember.computed('delivery.transportType.{allowLoadStepTypeChange,allowUnloadStepTypeChange}', function () {
      return this.get('delivery.stepType') === 'load' ? this.get('delivery.transportType.allowLoadStepTypeChange') : this.get('delivery.transportType.allowUnloadStepTypeChange');
    }),
    actions: {
      setWarehouseStep(deliveryStep, step) {
        this.setStep(deliveryStep, step, 'warehouse');
      },

      setRecipientStep(deliveryStep, step) {
        this.setStep(deliveryStep, step, 'address');
      },

      setPhonePrefix(phonePrefix) {
        this.set('step.contactPersonMobilePhonePrefix', phonePrefix);
      }

    }
  });

  _exports.default = _default;
});