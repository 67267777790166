define("apollo/pods/transaction/modals/choose-carrier/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    amountOfCoverService: Ember.inject.service(),
    errors: Ember.A([]),

    init() {
      this._super(...arguments);

      this.set('errors', []);
    },

    carriersList: Ember.computed('model.carriers.@each.{shortName,routeOfferPrice,disabled}', function () {
      return this.get('model.carriers');
    }),
    // eslint-disable-next-line
    sortedCarriersProperties: ["hasRouteOffer:desc", "routeOfferPrice:asc", "disabled:asc", "shortName:asc"],
    sortedCarriers: Ember.computed.sort('carriersList', 'sortedCarriersProperties'),
    title: Ember.computed('', function () {
      return this.get('intl').t('transaction.chooseCarrier');
    }),
    hint: Ember.computed('transaction.carrierMustAcceptTransport', function () {
      if (this.get('transaction.carrierMustAcceptTransport')) {
        return this.get('intl').t('transaction.carrierMustAcceptTransportHint.checked');
      } else {
        return this.get('intl').t('transaction.carrierMustAcceptTransportHint.unchecked');
      }
    }),
    translationKeyLabel: Ember.computed('transaction.transport.transportType', function () {
      return this.get('transaction.transport.transportType.priceLabelTranslation');
    }),
    transaction: Ember.computed('model', function () {
      return this.get('model.transaction');
    }),
    showChoosingReason: Ember.computed('transaction.{' + 'requireAuctionChoosingReason,' + 'requireTransactionChoosingReason,' + 'requireReasonFieldIfPriceIsZero,' + 'price,' + 'transport.deliveries.@each.freightCost}', function () {
      const deliveries = this.get('transaction.transport.deliveries');
      const anyDeliveryHasZeroCost = deliveries.any(d => {
        return d.get('freightCost') && Number(d.get('freightCost')) === 0;
      });
      const requireReasonFieldIfPriceIsZero = this.get('transaction.requireReasonFieldIfPriceIsZero') && (this.get('transaction.price') && Number(this.get('transaction.price')) === 0 || anyDeliveryHasZeroCost);
      return this.get('transaction.requireAuctionChoosingReason') || this.get('transaction.requireTransactionChoosingReason') || requireReasonFieldIfPriceIsZero;
    }),
    currencies: Ember.computed('', function () {
      return this.get('store').peekAll('currency');
    }),
    priceUpdateDisabled: Ember.computed('model.disabledEdition', 'transaction.carrier.routeOffer.id', function () {
      return this.get('model.disabledEdition') || !!this.get('transaction.carrier.routeOffer.id');
    }),
    // eslint-disable-next-line ember/no-observers
    expectedConfirmationDateChanged: Ember.observer('model.expectedConfirmationTime', 'model.expectedConfirmationDate', function () {
      Ember.run.once(this, '_expectedConfirmationDateChangedOnce');
    }),
    showCarrierMustAcceptTransportCheckbox: Ember.computed('', function () {
      const transportType = this.get('model.transaction.transport.transportType');
      const transactionSetting = this.get('sessionAccount').getTransactionSetting(transportType);
      return transactionSetting ? transactionSetting.get('showCarrierConfirmationCheckbox') : true;
    }),
    choosingReasonList: Ember.computed('model.transaction.transport.activeAuction', function () {
      let choosingReasonList;

      if (this.get('model.transaction.transport.activeAuction')) {
        choosingReasonList = this.get('store').peekAll('specialFieldOption').filter(sfo => sfo.get('field') === 'AUCTION_CHOOSING_REASON');
      } else {
        choosingReasonList = this.get('store').peekAll('specialFieldOption').filter(sfo => sfo.get('field') === 'MANUAL_PASS_CHOOSING_REASON');
      }

      return choosingReasonList.filter(sfo => sfo.get('transportType.id') === this.get('model.transaction.transport.transportType.id'));
    }),
    _expectedConfirmationDateChangedOnce: function () {
      const self = this;
      const expectedConfirmationTime = self.get('model.expectedConfirmationTime');
      const h = expectedConfirmationTime ? expectedConfirmationTime.split(':')[0] : '0';
      const m = expectedConfirmationTime ? expectedConfirmationTime.split(':')[1] : '0'; // ember-flatpickr wysyła nam listę dat -- dlatego musimy wziąć pierwszy element ([0])

      const expectedConfirmationDate = self.get('model.expectedConfirmationDate')[0] ? self.get('model.expectedConfirmationDate')[0] : self.get('transaction.expectedConfirmationDate');
      const date = moment(expectedConfirmationDate || new Date()).startOf('day').add(h, 'hours').add(m, 'minutes').toDate();

      if (self.get('transaction.expectedConfirmationDate') !== date) {
        self.get('transaction').set('expectedConfirmationDate', date);
      }
    },
    actions: {
      setTransactionCompany(company) {
        if (!company || company.get('disabled')) {
          return;
        }

        if (!this.get('amountOfCoverService').hasEnoughAmountOfCover(this.get('transaction.transport'), company)) {
          return;
        }

        const routeOffer = company.get('routeOffer');
        const transportType = this.get('transaction.transport.transportType');
        const transactionSetting = this.get('sessionAccount').getTransactionSetting(transportType);
        const defaultCurrencySymbol = transactionSetting ? transactionSetting.get('defaultCurrency') : 'PLN';
        const defaultCurrency = this.get('store').peekAll('currency').filterBy('symbol', defaultCurrencySymbol).get('firstObject');
        console.log(`Wybieramy przewoźnika ${company.get('shortName')} z ofertą ${routeOffer.get('price')}...`);
        this.get('transaction').setProperties({
          carrier: company,
          routeOffer,
          currency: routeOffer.get('currency') !== undefined ? routeOffer.get('currency') : defaultCurrency,
          price: routeOffer.get('price')
        });
      },

      passTransport: function () {
        try {
          const transaction = this.get('transaction');

          if (!transaction.validate()) {
            return;
          }

          const deliveries = transaction.get('transport.deliveries') || Ember.A([]);
          const deliveriesErrors = Ember.A([]);
          const showPriceFieldForEachDeliveries = transaction.get('showPriceForDeliveriesField');
          const showPriceField = transaction.get('showPriceField');

          if (deliveries.length > 0 && showPriceField) {
            deliveries.forEach(d => {
              if (!showPriceFieldForEachDeliveries) {
                d.set('freightCost', transaction.get('price'));
              }

              d.set('freightCostCurrency', transaction.get('currency'));
              d.set('requireFreightCostDuringTransaction', transaction.get('requirePriceFieldForDeliveries'));
              deliveriesErrors.push(d.validate({
                only: 'freightCost,freightCostCurrency'
              }));
            });

            if (deliveriesErrors.length > 0 && deliveriesErrors.some(d => {
              return d === false;
            })) {
              return console.error('Walidacja delivery zwróciła błąd: ' + deliveriesErrors);
            }
          }

          transaction.save().then(() => {
            (0, _jquery.default)('#main-modal').data('skip-cancel-action', true);
            this.send('hideModal');
          }).catch(response => {
            throw new Error(response.errors);
          });
        } catch (e) {
          this.set('errors', [e]);
          console.error(e);
        } finally {
          this.get('transaction.transport').reload();
        }
      },
      cancel: function () {
        console.debug('Wycofujemy zmiany w okienku przekazywania zlecenia do przewoźnika...');
        this.get('transaction').destroyRecord();
      },
      close: function () {
        this.send('hideModal');
      }
    }
  });

  _exports.default = _default;
});