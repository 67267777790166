define("apollo/pods/routes/modals/route-form/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    routeService: Ember.inject.service(),
    intl: Ember.inject.service(),
    route: Ember.computed('model', function () {
      return this.get('model');
    }),
    currencies: Ember.computed('', function () {
      return this.get('store').peekAll('currency');
    }),
    _save: function (route) {
      const self = this;

      if (!route.validate()) {
        return;
      }

      self.set('errors', Ember.A([]));
      route.save().then(updatedRoute => {
        console.debug('Zapisaliśmy trasę o id ' + updatedRoute.id);
        self.send('rollbackNewChanges');
        self.send('hideModal');
        self.send('refreshModel');
      }).catch(response => {
        if (response.errors && response.errors[0] === 'DUPLICATE') {
          self.set('errors', Ember.A([this.get('intl').t('route.errors.unique')]));
          return;
        }

        self.get('route').rollbackAttributes();
      });
    },
    actions: {
      rollbackNewChanges() {
        this.get('route.routeAddresses').filterBy('isNew', true).forEach(item => this.store.deleteRecord(item));
        this.get('route.routeCustomFields').filterBy('isNew', true).forEach(item => this.store.deleteRecord(item));
      },

      save() {
        const route = this.get('route');

        this._save(route);
      },

      edit(route) {
        this._save(route);
      },

      handleAddressesOrder() {
        console.log('TODO: Drag&Drop handleAddressesOrder...');
      },

      close() {
        this.send('rollbackNewChanges');
        this.send('hideModal');
      },

      cancel() {
        console.debug('Zamykamy formularz trasy...');
        this.send('rollbackNewChanges');
        this.set('errors', []);
      },

      async createTransport() {
        console.debug('Tworzymy transport z trasy...');
        this.send('showModal', 'transports.modals.create-transport', (await this.routeService.createTransportFromRoute(this.get('route'))));
      }

    }
  });

  _exports.default = _default;
});